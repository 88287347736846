@import '~styles/variables';
@import '~styles/mixins';

button.btn,
.btn {
  &-primary {
    @include font-style(16px, normal, white, center, 21px);
    @include border-style(1px solid $th-cyan, $radius: 10px);
    @include transition(0.4s all);
    @include display-flex(center, center);
    cursor: pointer;
    width: 100%;
    padding: 10px;
    background: $th-cyan;
    box-shadow: 0px 2px 4px rgba(23, 28, 52, 0.16);

    & > div {
      padding:0 8px;
    }

    &:focus,
    &:hover {
      @include font-style($color: white);
      background: $th-dark-cyan;
      border-color: $th-dark-cyan;
    }

    &[disabled],
    &:disabled {
      @include border-style(1px solid $th-grey2, $radius: 10px);
      background: $th-grey2;
      cursor: not-allowed;
      box-shadow: none;
    }

    & img {
      margin-right: 5px;
    }
  }

  &-secondary {
    @include font-style(16px, normal, $th-cyan, center, 21px);
    @include border-style(1px solid $th-cyan, $radius: 10px);
    @include transition(0.4s all);
    @include display-flex(center, center);
    width: 100%;
    padding: 10px;
    background: white;
    box-shadow: 0px 2px 4px rgba(23, 28, 52, 0.16);

    & > div {
      padding:0 8px;
    }

    &:hover {
      @include border-style(1px solid $th-dark-cyan);
      @include font-style($color: $th-dark-cyan);
      background: white;
      cursor: pointer;
    }

    &[disabled],
    &:disabled {
      @include font-style($color: $th-grey2);
      @include border-style(1px solid $th-grey2);
      background: white;
      cursor: not-allowed;
      box-shadow: none;
    }

    & img {
      margin-right: 5px;
    }
  }

  &-tertiary {
    @include font-style(16px, normal, $th-grey1, center, 21px);
    @include border-style(1px solid $th-grey1, $radius: 10px);
    @include transition(0.4s all);
    @include display-flex(center, center);
    width: 100%;
    padding: 10px;
    background: white;
    box-shadow: 0px 2px 4px rgba(23, 28, 52, 0.16);

    & > div {
      padding:0 8px;
    }

    &:hover {
      @include font-style($color: $th-dark-grey2);
      @include border-style(1px solid $th-dark-grey2);
      background: white;
      cursor: pointer;
    }

    &[disabled],
    &:disabled {
      opacity: 0.4;
      background: white;
      cursor: not-allowed;
      box-shadow: none;
    }

    & img {
      margin-right: 5px;
    }
  }

  &-home {
    @include font-style(18px, 600, #fff, center, 24px);
    @include border-style(3px solid $th-purple, $radius: 50px);
    background-color: $th-purple;
    padding: 12px;
    width: 100%;
    height: 56px;
    max-width: 280px;
    cursor: pointer;
    &:hover {
      color: #fff;
      background-color: #6a3489;
      border-color: #6a3489;
    }
  }
  &-home-transparent {
    @include font-style(18px, 600, $th-purple, center, 24px);
    @include border-style(3px solid $th-purple, $radius: 50px);
    background-color: transparent;
    box-shadow: 0 2px 4px 0 rgba(23, 28, 52, 0.16);
    padding: 12px;
    width: 100%;
    height: 56px;
    max-width: 280px;
    cursor: pointer;
    &:hover {
      color: #fff;
      background-color: #73408f;
      border-color: #73408f;
    }
  }

  &-home-transparent-white {
    @include font-style(18px, 600, white, center, 24px);
    @include border-style(3px solid white, $radius: 50px);
    background-color: transparent;
    box-shadow: 0 2px 4px 0 rgba(23, 28, 52, 0.16);
    padding: 12px;
    width: 100%;
    height: 56px;
    max-width: 280px;
    cursor: pointer;
    &:hover {
      color: #fff;
    }
  }
}

@media screen and (max-width: 767px) {
  .btn {
    &-home-transparent-white,
    &-home-transparent,
    &-home {
      padding: 6px;
      height: 40px;
    }
  }
}
