.avatarContainer {
  position: relative;
  height: 35px;
}

.avatarElement {
  position: absolute;
  background-position: center;
  background-size: cover;
  background-color: #ffffff;
  height: 140px;
  width: 140px;
  border-radius: 50%;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  border: 5px solid #FFFFFF;
}

.childrenContainer {
  padding: 24px 40px;
}

.footerContainer {
  background-color: #F1F8F7;
  min-height: 70px;
  width: 100%;
  padding: 20px 48px;
  font-size: 16px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 767px) {
  .childrenContainer {
    padding: 35px 20px 20px;
  }

  .footerContainer {
    padding: 20px;
  }
}
