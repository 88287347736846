@import '~styles/_variables';
@import '~styles/mixins';

.logoSection {
  @include display-flex();
}
.lessonBanner {
  @include display-flex(space-between, center);
  @include border-style($border-left: 1px solid $th-pink, $border-right: 1px solid $th-pink);
  background: rgba(255, 114, 145, 0.1);
  padding: 0 10px;
  margin: 0 10px;
  height: 64px;
  min-width: 220px;

  .icon {
    @include display-flex(center, center);
  }

  .timeInfo {
    @include display-flex($direction: column);
    @include font-style(16px, 600, $th-middle-gray, center, 21px);
    margin-left: 8px;

    a {
      @include font-style(16px, normal, $th-purple, center, 21px);
    }
  }
}

#header {
  @include position(fixed, 0, $z-index: 1000);
  @include display-flex($align: center);
  background: rgba(255, 255, 255, 0.90);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(10px);
  padding: 0 50px;
  width: 100%;
  height: 80px;
  @include maxWidth1000{
    padding: 0 20px;
    // height: 128px;
    align-items: flex-end;
    padding-bottom: 10px;
    box-shadow: none;
  }
  .findTutor{
    position: absolute;
    width: 280px;
    height: 55px;
    background-color: #0077FF;
    border-radius: 0 0 24px 24px;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    @include font-style(16px, 600, $th-white,$lineHeight:55px);
    font-family: 'Roboto',sans-serif;
    letter-spacing: 0.5px;
    display: flex;
    justify-content: center;
    span{
      margin-left: 7px;
    }
    @include maxWidth1000{
      top: 0;
      bottom: auto;
    }
  }
  .navMenu {
    @include display-flex($align: center);
    border: none;
    box-sizing: border-box;
    height: 64px;
    margin-right: 10px;

    & > li,
    & > a {
      @include display-flex($align: center);
      height: 100%;
      transition: none;
      border-bottom: 4px solid transparent;
      padding: 0 10px
    }

    .navItem {
      @include font-style(14px, 500, #111,$lineHeight:24px);
      font-family: 'Poppins', sans-serif;
      @include position(relative);

      &.active {
        border-bottom: 4px solid #111;
      }
    }
    &:hover {
      @include font-style($color: $th-purple);
    }
   
  }

  .contactUsItem {
    @include font-style(16px, 400, $th-cyan, $lineHeight: 21px);
    @include display-flex(center, center, wrap);
    // @include border-style($border-left: 2px solid $th-purple, $border-bottom: none !important);

    max-width: 120px;
    max-height: 46px;

    @media(max-width: 1080px) {
      max-width: 104px;
      @include font-style(14px, 400, $th-cyan, $lineHeight: 18px);
      @include display-flex(center, center, wrap);
    }

    @media(max-width: 767px) {
      margin: 4px auto 8px;
      @include display-flex(center, center, wrap);
      @include border-style($border: none);
    }
  }

  .contactUs {
    @include font-style(14px, 400, $th-cyan !important, $lineHeight: 18px !important);


    &:hover {
      @include font-style($color: $th-dark-cyan !important);
    }
  }

  .phoneNumber {
    @include font-style(21px, 500, $th-purple);
    white-space: nowrap;
    width: 174px;
    img {
      margin: 0 9px 0 0;
    }
  }

  .dropDown {
    &Arrow {
      padding: 0 8px;
    }

    &Label {
        @include font-style(16px, 500, #111,$lineHeight:24px);
        font-family: 'Poppins', sans-serif;
        letter-spacing: 0.5px;
        padding: 0 6px 0 16px;

      & > span {
        @include position(relative);

        .countBadge {
          @include position(absolute, $top: 15%);
        }
      }

      &.dropDownOpened {
        border-bottom: 4px solid #111;
      }
    }

  }
  .btn {
    @include border-style($radius: 10px);
    @include display-flex(center, center);
    padding: 4px 15px;
    height: 40px;
  }

  .navSubmenu {
    @include font-style(16px, 500, #111,$lineHeight:24px);
      font-family: 'Poppins', sans-serif;
      letter-spacing: 0.5px;
    margin: 0;
    transition: none;
    border-bottom: 2px solid transparent !important;

    &:hover {
      div {
        color: #111;
      }
    }
  }

  div.rightDropAuthMenu {
    // border-left: 2px solid $th-purple;
    padding-left: 20px;
    height: 75%;

    a {
      @include font-style($color: $th-cyan);
      display: flex;
      align-items: center;
    }
    &.userName{
      display: flex;

     a{
      width: 200px;
      span{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
     }
    }
  }

  div.mobilePhone {
    @include display-flex(center, center, $direction: column);
    @include font-style(16px, 600, $th-cyan, center, 21px);

    a {
      @include font-style(16px, normal, $th-cyan, center, 21px);
    }
  }

  .navHeader {
    @include display-flex($align:center, $direction: row);
    // width: 100%;
    flex:1;

    a {
      color:#111;
    }

    .mobileLogo {
      @include display-flex(null, center);
    }
 
  }
  .meunList{
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .navLogo {
    @include display-flex();
    // padding-bottom: 4px;
    height: 50px;
    // width: 127.5px;
  }

  .loginSignUp{
    width: 178px;
    height: 48px;
    border-radius: 200px;
    border: 1px solid #000;
    @include font-style(16px, 600, #000,center,$lineHeight:48px);
    letter-spacing: 0.5px;
    font-family:'Roboto',sans-serif;
    display: inline-block;
    @include maxWidth766{
      width: 158px;
    }
    &:hover{
      font-size: 18px;
      color: $th-white;
      border-color: transparent;
      background: linear-gradient(93.03deg, #00D4A6 3.09%, #0F91FF 39.56%, #CD73FF 79.05%, #F97691 98.43%);
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  }
  }

  .mobileIcon {
    @include font-style(1.5em, $color: $th-purple);
    width: 21px;
    margin-right: 0;
  }
  #desktopNav{
    @include maxWidth1000{
      display: none;
    }
  }
  #mobileNav{
    @include minWidth1000{
      display: none;
    }

  }
  @media (max-width: 767px) {

    .phoneNumber {
      @include font-style(16px, 500, $th-cyan, center, 21px);
      white-space: nowrap;
    }
  }

}

.mobile {
  display: none;
}

.contactUsIcon {
  margin-right: 8px;
  height: 21px;
}

div.dropDownOverlay {
  @include position(fixed, 64px!important);
  background: $th-white;
  box-shadow: 0px 3px 16px 5px rgba(98, 104, 110, 0.46);
  border-radius: 10px;
  padding: 0;

  & > ul {
    box-shadow: unset;

    li {
      padding: 12px 24px;

      &:hover {
        background-color: $th-light-purple;
      }
      a {
        @include font-style(16px, 500, #111,$lineHeight:24px);
        font-family: 'Poppins', sans-serif;
        letter-spacing: 0.5px;
      }
    }
  }
}


.countBadge {
  @include font-style(12px, $color: white, $align: center, $lineHeight: 16px);
  @include square(20px);
  display: inline-block;
  background: $th-pink;
  border-radius: 20px;
  padding: 3px;
  margin-left: 4px;
}

.redDot {
  @include position(absolute, 15px, 10px);
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}


ul.mobileMenu {
  @include position(absolute, 80px, $left: 0, $z-index: 9);
  min-height: 100vh;
  background:white;
  overflow: auto;
  max-height: 100vh;
  padding-bottom: 150px;
  padding-top: 12px;

  .navSubmenu {
    border-bottom: 1px solid #ebeaec;
    @include font-style(16px, 500, #111,$lineHeight:24px);
      font-family: 'Poppins', sans-serif;
      letter-spacing: 0.5px;
  }

  .navItem {
    @include font-style(14px, 500, #111,$lineHeight:24px);
    font-family: 'Poppins', sans-serif;
    & > span {
      @include position(relative);

      .countBadge {
        @include position(absolute, $top: -2px);
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .lessonBanner {
    padding: 0 3px;
    margin: 0 0 0 5px;
    min-width: 140px;

    .timeInfo {
      @include font-style(12px, 600, $th-middle-gray, center, 19px);
      margin-left: 4px;

      a {
        @include font-style(12px, normal, $th-purple, center, 19px);
      }
    }
  }
}


@media screen and (max-width: 1080px) {
  #header {
    .navMenu {
        li {
          padding: 0 4px;
        }
        a {
          span {
            @include font-style(16px, 500, #111,$lineHeight:24px);
            font-family: 'Poppins', sans-serif;
            letter-spacing: 0.5px;
          }
      }

      .navItem {
        @include font-style(14px, 500, #111,$lineHeight:24px);
        font-family: 'Poppins', sans-serif;
      }
    }

    .dropDown {
      &Arrow {
        padding: 0 4px;
        height: 7px;
        width: 28px;
      }

      &Label {
        @include font-style(16px, 500, #111,$lineHeight:24px);
        font-family: 'Poppins', sans-serif;
        letter-spacing: 0.5px;
        padding: 0 6px;

        & > span {
          @include font-style(14px);
        }
      }

    }
  }
}

@media screen and (max-width: 768px) {
  .mobile {
    display: none;
  }

  .lessonBanner {
    @include display-flex(center, center);
    @include border-style(none);
    margin: 0;
    min-width: 100%;

    .timeInfo {
      @include font-style(16px, 600, $th-middle-gray, center, 21px);
      margin-left: 4px;

      a {
        @include font-style(16px, normal, $th-purple, center, 21px);
      }
    }
  }
}

.oktopiLogo {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  // margin-top: 1rem;
  height: 28px;
  &:hover {
    cursor: pointer;
  }
}

.modalBody {
  padding: 24px;
  margin: 50px 0;

  .title {
    @include font-style(24px, 600, $th-black, center, 32px);
    margin-bottom: 18px;
    img {
      height: 40px;
    }
  }
}

.btns {
  @include display-flex(space-between, center);
  margin-top: 16px;

  button {
    padding: 10px 32px;
    height: 40px;
    max-width: 200px;

    &:first-child {
      max-width: 130px;
      margin-right: 1rem;
    }
  }
}

.dialogContent {
  padding: 1rem 0;
  p {
    font-size: 1rem;
    margin: 0;
    text-align: center;
  }
}
