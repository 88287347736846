@import '~styles/mixins';
@import '~styles/variables';

.footer {
  @include display-flex(space-between);
  padding: 15px 55px 15px 55px;
  width:  100%;
  background: white;

  .copyright {
    @include font-style(14px, normal, $th-black, $lineHeight: 19px);
  }

}
.social {
  @include display-flex($align: center);

  .phone {
    @include display-flex($align: center);

    img {
      padding-right: 4px;
    }

    a {
      @include font-style(16px, normal, $th-purple, $lineHeight: 21px);
    }
  }

  .socialLinks {
    margin-left: 30px;

    img {
      padding: 0 14px;
    }
  }
}

.mobileFooter {
  display: none;
  background: white;
  padding-top: 8px;

  .navBar {
    @include position(relative);

    .countBadge {
      @include position(absolute, $top: -2px, $right: -5px);
      @include font-style(12px, $color: white, $align: center, $lineHeight: 16px);
      @include square(20px);
      display: inline-block;
      background: $th-pink;
      border-radius: 20px;
      padding: 3px;
      margin-left: 4px;
    }
  }

  .menuItem {
    @include display-flex(center, center, $direction: column);
    @include position(relative);
    @include border-style($border-bottom: 4px solid transparent);
    cursor: pointer;

    a {
      text-align: center;
    }

    .label {
      @include font-style(14px, normal, $th-grey1);
    }

    &.active {
      @include border-style($border-bottom: 4px solid $th-purple);

      .label {
        @include font-style(14px, normal, $th-purple);
      }
  }

    &.notifyLabel {
      &:after {
        @include position(absolute, 12px, $left: 80%);
        @include square(8px);
        @include border-style(none, $radius: 50%);
        content: ' ';
        display: block;
        background: $th-pink;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .footer {
    display: none;
  }

  .mobileFooter {
    @include display-flex(space-around, center);
  }
}
