
@import '~styles/mixins';
@import '~styles/variables';

.icon {
  color: rgba(0, 0, 0, 0.25);
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .forgotPassword {
    :hover {
      cursor: pointer;
    }
  }
}

.loginBtn {
  width: 100%;
  margin: 1em 0;
}

.createAccountBtn {
  color: #7d3fa8;
  margin-left: 0.5em;
}


.noAccount {
	margin: 40px 0;
}

.goSignupBtn {
  @include font-style(16px, $color: $th-purple);
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}

.goSignupText {
  @include font-style(16px);
}