@import '~styles/mixins';

.eiContentWrapper {
  @include display-flex();
  border-radius: 24px;
  background: #000;
  justify-content: center;

  .closeBtn {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    z-index: 1;
  }

  .leftCircle {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100.8px;
  }

  .rightCircle {
    position: absolute;
    top: 0;
    right: 0;
    height: 160px;
  }

  .leftBg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
  }

  .waveBg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    height: auto;
  }

  .descForm {
    display: flex;
    flex-direction: column;
    margin: 40px 0 40px 95px;
    // flex: 1;
    width: 48%;
    z-index: 1;

    .descTitle {
      // font-family: 'Inter';
      font-style: italic;
      font-weight: 600;
      font-size: 27px;
      line-height: 42px;
      // color: #7D3FA8;
      color: white;
      margin-top: 20px;
    }
    .descInfo {
      // font-family: 'Inter';
      font-style: italic;
      font-weight: 900;
      font-size: 42px;
      line-height: 48px;
      // color: #000000;
      color: white;
      margin: 10px 0;

      .descInfoCenter {
        color: #7D3FA8;
      }
    }

    .descBox {
      @include display-flex(space-between, center);
      box-sizing: border-box;
      max-width: 246px;
      height: 58px;
      // background: #FFFFFF;
      // border: 1px solid #FFFFFF;
      background: #FFA703;
      border: 1px solid #FFA703;
      border-radius: 20px;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 20px;
      margin-bottom: 40px;

      .descBoxText {
        // font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 30px;
        // color: #7D3FA8;
        color: white;
      }
    }
  }

  .mainLady {
    position: absolute;
    right: 100px;
    bottom: 0px;
    align-self: flex-end;
    // margin-right: 42px;
    z-index: 1;
    // width: 50%;
    // height: auto;
    max-height: 90%;
    width: 40%;
  }
}
.contentWarp{
  background-color: #000;
  padding: 66px 33px;
  border-radius: 24px;
}
.nomalText{
  @include font-style(18px,400,#fff, $lineHeight: normal);
}
.ourTeam{
  margin: 12px 0;
}
.upTo{
  @include font-style(36px,500,#fff, $lineHeight: normal);
  margin:16px 0;
}
.Secure{
  @include font-style(12px,400,#fff,$lineHeight: normal);
}
.asteriskFineprint{
  padding-top: 24px;
  @include font-style(10px,400,#fff,$lineHeight: normal);
}
.signUpBtnWarp{
  margin: 26px 0;
  display: flex;
  justify-content: flex-end;
}
.signUpBtn{
  @include font-style(22px,400,#fff,center, $lineHeight: normal);
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 12px;
  background: linear-gradient(99.03deg, #fbb03b , #CD73FF,#5D3FD3 );
  border: none;
}