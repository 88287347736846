@import '~styles/variables';
@import '~styles/mixins';

 .loginFooterWarp{
  background-color: $th-black;
  color: #BDBDBD;
  padding: 0 50px;
  height: 128px;
  @include display-flex(space-between);
  align-items:center;
  .CopyrightDate{
    @include display-flex();
    align-items:center;

  }
  .privacyItems {
    margin:0 24px;
  }

  .thFooterItem {
    cursor: pointer;
    font-weight: 400;
    margin: 0 5px;
      color: #BDBDBD;

  }

 }
 @media screen and (max-width: 767px) {
  .loginFooterWarp{
    @include display-flex($direction: column);
    padding: 0 12px;
    align-items: inherit;
    .CopyrightDate {
      @include display-flex($direction: column);
      align-items: inherit;
      img{
        margin: 0 16px;
        height: 30px;
      }
    }
    .privacyItems{
      margin: 0 16px;
    }
    .actionItems{
      margin-bottom: 12px;
      margin-left: 10px;
    }
  }
}

.unLoginFooterWarp{
  background-color: $th-black;
  color: #BDBDBD;
  padding: 137px 100px 111px;
  font-family:'Inter', sans-serif;
  display: flex;
  @include maxWidth1000{
    padding: 90px 40px;
}
  @include maxWidth766{
    flex-direction: column;
    padding: 60px 20px;
  }
  .CopyrightDate{
    @include display-flex();
    flex-direction: column;
   
  }
  .privacyItems {
    margin-top: 159px;
    @include font-style(16px, 200, #fff, $lineHeight:normal);
    @include maxWidth766{
        margin-top:20px;
        margin-bottom: 40px;
    }
  }
  .actionItemWarp{
    flex: 1;
    @include maxWidth1000{
        margin-left: 20px;
    }
    @include maxWidth766{
        margin-left: 0;
    }
    @media (min-width: 420px) {
      display: flex;
      flex-direction: row;
    }
  }
  .actionItem{
    display: flex;
    flex-direction: column;
    .thFooterItem{
        @include font-style(18px, 500, #fff, $lineHeight:normal);
        margin-bottom: 19px;
        &.loginIn{
            margin-top: 19px;
            width: 106px;
            height: 36px;
            border-radius: 29px;
            background: $th-light-cyan; 
            display: flex;
            justify-content: center;
            align-items: center;      
        }
    }
    .navSubmenu{
        color:#fff;
    }

  }

 }
 .dropDownLabelUae{
    @include font-style(18px, 500, #fff, $lineHeight:normal);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include maxWidth766{
        justify-content: flex-start;
        margin-top: 10px;
    }
    .dropDownArrow{
        margin-left: 8px;
        display: flex;
    }
    &:hover{
    color: #fff;
    }
 }


