@import '~styles/_variables';
@import '~styles/mixins';


.uaeLogoSection {
  @include display-flex($align: flex-end);
}
.uaeLogoText{
  @include font-style(29px, 500, $th-black, $lineHeight:21px);
  margin: 0 12px;
}
.lessonBanner {
  @include display-flex(space-between, center);
  @include border-style($border-left: 1px solid $th-pink, $border-right: 1px solid $th-pink);
  background: rgba(255, 114, 145, 0.1);
  padding: 0 10px;
  margin: 0 10px;
  height: 64px;
  min-width: 220px;

  .icon {
    @include display-flex(center, center);
  }

  .timeInfo {
    @include display-flex($direction: column);
    @include font-style(16px, 600, $th-middle-gray, center, 21px);
    margin-left: 8px;

    a {
      @include font-style(16px, normal, $th-purple, center, 21px);
    }
  }
}

#headerUae {
  @include position(fixed, 0, $z-index: 1000);
  @include display-flex($align:flex-end );
  background-color: #fff;
  padding: 0 41px 0 81px;
  width: 100%;
  // box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.08);
  font-family:'Inter', sans-serif;
  height: 110px;
  @include maxWidth1000{
    padding:0 50px;
  }
  @include maxWidth766{
    @include display-flex($align: center);
    padding: 0 24px;
  }

  .navMenu {
    @include display-flex($align: center);
    border: none;
    box-sizing: border-box;
    height: 64px;
    font-family:'Inter', sans-serif;
    
    &.noTokenNavMenu{
      & > li{
        padding: 0 0 0 61px;
        @include maxWidth1000{
          padding: 0 0 0 20px;
        }
      }
    }

    & > li{
      border: none!important;
      height: 100%;
      & > a {
        @include display-flex($align: center);
        height: 100%;
        transition: none;
        border-bottom: 4px solid transparent;
      }
    }
    @include maxWidth1000{
      li {
        padding: 0 4px;
      }
      a {
        span {
          @include font-style(14px, $lineHeight: 14px);
        }
      }
    }
    

    .navItem {
      @include font-style(18px,500,#000, $lineHeight: normal);
      @include position(relative);

      &:hover {
        @include font-style($color: $th-purple !important);
      }

      &.active {
        border-bottom: 4px solid $th-purple;
      }
    }
    .uaeNavItem {
      @include font-style(18px,500,#000, $lineHeight: normal);
      @include position(relative);
      
      &.isCN {
        @media(max-width: 1280px) {
          font-size: 14px;
        }
      }

      &.active {
        border-bottom: 4px solid $th-black;
      }
    }

    &:hover {
      @include font-style($color: $th-purple);
    }
    .deskLogin{
      margin-left:177px ;
      margin-right:41px ;
      height: 36px;
      width: 106px;
      border-radius: 29px;
      background: $th-light-cyan;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include maxWidth1000{
       margin: 0 20px;
      }
      a{
        color: #fff;
        &:hover{
          color: #fff;
        }
      }
      .uaeNavItem {
        &.active {
          border-bottom: 4px solid transparent;
        }
      }
      @media(max-width: 1280px) {
        margin-left: 100px;
      }
    }
  }

  .contactUsItem {
    @include font-style(16px, 400, $th-cyan, $lineHeight: 21px);
    @include display-flex(center, center, wrap);
    // @include border-style($border-left: 2px solid $th-purple, $border-bottom: none !important);

    max-width: 120px;
    max-height: 46px;

    @media(max-width: 1080px) {
      max-width: 104px;
      @include font-style(14px, 400, $th-cyan, $lineHeight: 18px);
      @include display-flex(center, center, wrap);
    }

    @media(max-width: 767px) {
      margin: 4px auto 8px;
      @include display-flex(center, center, wrap);
      @include border-style($border: none);
    }
  }

  .contactUs {
    @include font-style(14px, 400, $th-cyan !important, $lineHeight: 18px !important);


    &:hover {
      @include font-style($color: $th-dark-cyan !important);
    }
  }

  .phoneNumber {
    @include font-style(21px, 500, $th-purple);
    white-space: nowrap;
    width: 174px;
    img {
      margin: 0 9px 0 0;
    }
  }

  .dropDown {
    &Arrow {
      padding: 0 8px;
    }
    &LabelUae {
      @include font-style(18px,500,#000, $lineHeight: normal);
      padding: 0 6px 0 16px;
      display: flex;
      height: 100%;
      align-items: center;

      & > span {
        @include position(relative);

        .countBadge {
          @include position(absolute, $top: 15%);
        }
      }

      &.dropDownOpened {
        border-bottom: 4px solid $th-purple;
      }
    }

  }
  .btn {
    @include border-style($radius: 10px);
    @include display-flex(center, center);
    padding: 4px 15px;
    height: 40px;
  }

  .navSubmenu {
    @include font-style(16px, 400, $th-purple);
    margin: 0;
    transition: none;
    border-bottom: 2px solid transparent !important;

    &:hover {
      div {
        color: $th-purple;
      }
    }
  }

  div.rightDropAuthMenu {
    // border-left: 2px solid $th-purple;
    padding-left: 20px;
    height: 75%;

    a {
      @include font-style($color: $th-cyan);
      display: flex;
      align-items: center;
    }
    &.userName {
      a {
        width: 200px;
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    &.language {
      padding-left: 0px;
      a {
        color: #000000;
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        @media(max-width: 1280px) {
          font-size: 18px;
        }
      }
    }
    &.isCN {
      a {
        @media(max-width: 1280px) {
          font-size: 14px;
        }
      }
    }
  }

  div.mobilePhone {
    @include display-flex(center, center, $direction: column);
    @include font-style(16px, 600, $th-cyan, center, 21px);

    a {
      @include font-style(16px, normal, $th-cyan, center, 21px);
    }
  }

  .navHeader {
    @include display-flex(space-between, center, $direction: row);
    width: 100%;

    a {
      color:$th-purple;
    }

    .mobileLogo {
      @include display-flex(null, center);
    }
    .uaeMobileLogo{
      @include display-flex($align: flex-end);
    }
  }
  .uaeNavHeader{
    @include display-flex(space-between, center, $direction: row);
    width: 100%;

    a {
      color:$th-black;
    }

    .mobileLogo {
      @include display-flex(null, center);
    }
    .uaeMobileLogo{
      @include display-flex($align: flex-end);
    }
  }

  .navLogo {
    @include display-flex();
    height: 50px;
  }

  .mobileIcon {
    @include font-style(1.5em, $color: $th-purple);
    width: 21px;
    margin-right: 0;
  }

  @media (max-width: 999px) {
    #desktopNavUae {
      display: none;
    }

    .phoneNumber {
      @include font-style(16px, 500, $th-cyan, center, 21px);
      white-space: nowrap;
    }
  }

  @media (min-width: 1000px) {
    #mobileNavUae {
      display: none;
    }
  }
}


.mobile {
  display: none;
}

.contactUsIcon {
  margin-right: 8px;
  height: 21px;
}

div.uaedropDownOverlay {
  @include position(fixed, 110px!important);
  background: $th-white;
  box-shadow: 0px 3px 16px 5px rgba(98, 104, 110, 0.46);
  border-radius: 10px;
  padding: 0;
  @include maxWidth766{
    @include position(fixed, 64px!important);
  }

  & > ul {
    box-shadow: unset;

    li {
      padding: 12px 24px;

      &:hover {
        background-color: $th-light-purple;
      }
      a {
        @include font-style(16px, normal, $th-black, $lineHeight: 21px);
      }
    }
  }
}

.countBadge {
  @include font-style(12px, $color: white, $align: center!important, $lineHeight: 16px);
  @include square(20px);
  display: inline-block;
  background: $th-pink;
  border-radius: 20px;
  padding: 3px;
  margin:0 4px;
}

.redDot {
  @include position(absolute, 15px, 10px);
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}


ul.mobileMenu {
  @include position(absolute, 110px, $left: 0, $z-index: 9);
  min-height: 100vh;
  background:white;
  overflow: auto;
  max-height: 100vh;
  padding-bottom: 150px;
  @include maxWidth1000{
    padding-left: 28px;
  }
  @include maxWidth766{
    padding-left: 0;
  }

  .navSubmenu {
    border-bottom: 1px solid #ebeaec;
  }

  .navItem {
    @include font-style(14px, 400, $th-purple);
    & > span {
      @include position(relative);

      .countBadge {
        @include position(absolute, $top: -2px);
      }
    }
  }
  .uaeNavItem{
    @include font-style(14px, 400, $th-black);
    & > span {
      @include position(relative);

      .countBadge {
        @include position(absolute, $top: -2px);
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .lessonBanner {
    padding: 0 3px;
    margin: 0 0 0 5px;
    min-width: 140px;

    .timeInfo {
      @include font-style(12px, 600, $th-middle-gray, center, 19px);
      margin-left: 4px;

      a {
        @include font-style(12px, normal, $th-purple, center, 19px);
      }
    }
  }
}


@media screen and (max-width: 1080px) {
  #header {
    .navMenu {
   

      .navItem {
        @include font-style(14px, 400, $th-purple);
      }
      .uaeNavItem{
        @include font-style(14px, 400, $th-black);
      }
    }

    .dropDown {
      &Arrow {
        padding: 0 4px;
        height: 7px;
        width: 28px;
      }

      &Label {
        @include font-style(14px, 400, $th-pink);
        padding: 0 6px;

        & > span {
          @include font-style(14px);
        }
      }

    }
  }
}

@media screen and (max-width: 768px) {
  .mobile {
    display: none;
  }

  .lessonBanner {
    @include display-flex(center, center);
    @include border-style(none);
    margin: 0;
    min-width: 100%;

    .timeInfo {
      @include font-style(16px, 600, $th-middle-gray, center, 21px);
      margin-left: 4px;

      a {
        @include font-style(16px, normal, $th-purple, center, 21px);
      }
    }
  }
}

.oktopiLogo {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  // margin-top: 1rem;
  height: 28px;
  &:hover {
    cursor: pointer;
  }
}

.modalBody {
  padding: 24px;
  margin: 50px 0;

  .title {
    @include font-style(24px, 600, $th-black, center, 32px);
    margin-bottom: 18px;
    img {
      height: 40px;
    }
  }
}

.btns {
  @include display-flex(space-between, center);
  margin-top: 16px;

  button {
    padding: 10px 32px;
    height: 40px;
    max-width: 200px;

    &:first-child {
      max-width: 130px;
      margin-right: 1rem;
    }
  }
}

.dialogContent {
  padding: 1rem 0;
  p {
    font-size: 1rem;
    margin: 0;
    text-align: center;
  }
}
