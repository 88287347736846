.buttonContainer {
  min-height: 50px;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 0;
  border-radius: 5px;
  overflow: hidden;

  &:focus {
    outline: 0;
  }
}

.imageContainer {
  display: inline-flex;
  width: 56px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  flex: 0 0 56px;
  background-color: #ffffff;

  img {
    max-width: 25px;
    max-height: 25px;
  }
}

.textContainer {
  display: inline-flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  flex: 1 1 auto;
  color: #ffffff;
  text-align: center;
}
