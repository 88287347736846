@import 'styles/variables';
@import 'styles/ant-next';
@import 'styles/_buttons';

:root {
  --th-grey: #686f75;
  --th-grey1: #8f8f8f;
  --th-grey2: #d1d1d1;
  --th-grey3: #9a9c9e;
  --th-grey4: #4f4950;
  --th-grey5: #979797;
  --th-light-grey: #ededed;
  --th-dark-grey: #686369;
  --th-dark-grey2: #77787c;
  --th-secondary: #77869e;
  --th-purple: #7d3fa8;
  --th-purple-middle: #c9afdb;
  --th-light-purple: #a98dbb;
  --th-cyan: #27d6c0;
  --th-dark-cyan: #23ac9e;
  --th-dark-violet: #32325d;
  --th-pink: #ff7291;
  --th-yellow: #ffd565;
  --th-black: #130217;
  --th-light-black: #4e4e4e;
  --th-light-purple: #f8f4fb;
  --th-middle-gray: #62686e;
  --th-purple-medium: #e5dfe9;
  --th-purple-medium2: #af96c1;
  --th-light-green: #f2fdfb;
  --th-medium-green: #7be2d8;
  --th-alert: #ffa704;
  --th-error: #df2065;
  --th-white: #ffffff;
  --th-han-purple: #4200ff;
  --th-red: #ff0000;
  --th-blue: #2400FF;
  --th-light-blue: #0600ffbd;
  --th-dark-gray: #595959;
}

/**
* App wide styles and helpers
*/
html {
  scroll-behavior: smooth;
}

body {
  // font-family: NotoSansArabic, sans-serif;
  display: flex;
  width: 100%;
}
* {
  outline: none;
}
#root {
  width: 100%;
}

/**
* Typography
*/

h1,
h2,
h3,
h4 {
  color: #171c34;
  font-weight: 400;
  text-align: left;
}

h1 {
  font-size: 3.3em;
  line-height: 54px;
  text-align: center;
}

h1.th-title {
  color: #000;
  font-size: 4em;
  letter-spacing: -2.5px;
  line-height: 64px;
  text-align: left;
  margin-bottom: 0.5em;
}

h2 {
  font-size: 2.7em;
  line-height: 36px;
}

h3 {
  font-size: 1.5em;
  line-height: 29px;
}

p {
  color: #62686e;
  font-size: 1.2em;
  font-weight: 300;
  line-height: 20px;
  // text-align: left;

  &.th-small {
    font-size: 1em;
  }

  &.th-sub-message {
    color: $th-grey;
    font-size: 1em;
    font-weight: 300;
    letter-spacing: -0.36px;
    opacity: 0.7;
  }

  &.th-sub-heading {
    color: #000;
    font-size: 1.4em;
    font-weight: 300;
    letter-spacing: -0.36px;
    line-height: 25px;
    opacity: 0.5;
  }
}

pre {
  color: #62686e;
  font-family: FaktPro, sans-serif;
  font-size: 1.1em;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

li {
  text-align: left;
  margin: 5px;
}

ol {
  margin: 10px;
}

ul {
  margin: 5px;
}

/**
* Helper and Utility classes
*/

h1,
h1.th-title,
h2,
h3,
p,
div,
span,
b,
i,
a {
  &.th-right {
    text-align: right;
  }

  &.th-left {
    text-align: left;
  }

  &.th-centre {
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
  }

  &.th-purple {
    color: $th-purple;
  }

  &.th-light-purple {
    color: $th-light-purple;
  }

  &.th-cyan {
    color: $th-cyan;
  }

  &.th-pink {
    color: $th-pink;
  }

  &.th-yellow {
    color: $th-yellow;
  }

  &.th-grey {
    color: $th-grey;
  }

  &.th-italics {
    font-style: italic;
  }
}

.th-top-space {
  margin-top: 2em;
}

.th-bottom-space {
  margin-bottom: 2em;
}

/**
* Common flex grid boxes used throughout the app
*/
.th-boxes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  justify-content: center;
  margin: 1em 0 5em 0;

  .th-box-title {
    font-size: 1.3em;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.33px;
    text-align: center;
    margin: 1em 0;
  }

  .th-box-text {
    color: $th-grey;
    margin: 1em 0;
    text-align: center;
    letter-spacing: -0.27px;
    font-weight: 300;
    line-height: 20px;
    font-size: 1.1em;
    padding: 15px;
  }

  .th-box-img {
    padding-top: 5%;
    width: 90%;
  }
}

.th-main-btn {
  color: white;
  background-color: $th-cyan;
  border-color: $th-cyan;
}

.th-outline-btn {
  color: $th-cyan;
  background-color: white;
  border-color: $th-cyan;
}

.th-danger-btn {
  color: white;
  background-color: $th-pink;
  &:hover {
    color: $th-pink;
    border-color: $th-pink;
  }
}

.th-align-center {
  display: flex;
  align-items: center;
}

.th-blockquote {
  margin: 1.5em auto;
  padding: 1.5em 0;
  color: #6d5b7b;
  font-size: 27px;
  font-style: italic;
  font-weight: 300;
  line-height: 32px;
  width: 70%;
  text-align: center;
  border-top: 2px solid #f77281;
  border-bottom: 2px solid #f77281;
}

@media (max-width: 767px) {
  .th-boxes {
    flex-wrap: wrap;
  }

  .th-blockquote {
    width: 95%;
  }
}

.th-word-divider {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  color: #62686e;
  font-size: 16px;

  & > span {
    position: relative;
    display: inline-block;
    font-weight: 300;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 9999px;
      height: 1px;
      background-color: #ededed;
    }

    &:before {
      right: 100%;
      margin-right: 15px;
    }

    &:after {
      left: 100%;
      margin-left: 15px;
    }
  }
}

.th-form-item-children-block {
  .ant-form-item-children {
    display: block !important;
  }
}

.th-margin-0 {
  margin: 0 !important;
}

.th-width-100 {
  width: 100% !important;
}

.th-sticky-container {
  height: 100% !important;
}

.container {
  width: 75%;
  margin: 0 auto;
  max-width: 1170px;
}

@media screen and (max-width: 767px) {
  .container {
    width: 100%;
    padding: 0 24px;
  }

  div#hubspot-messages-iframe-container {
    bottom: 64px !important;
    max-height: calc(100vh - 64px);
  }
}
