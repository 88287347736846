@import '~styles/variables';
@import '~styles/mixins';

#th-main-footer {
  color: #130217;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  background-color: #e5dfe9b2;
  padding: 50px 25px;

  .social-cta-links {
    display: flex;
    flex-direction: column;
    max-width: 158px;

    &--mobile {
      align-items: unset;
      .privacy-items {
        padding-bottom: 4rem;
      }
    }
  }

  .cta-btn {
    display: block;
    border-radius: 3px;
    text-align: center;
    color: #fff !important;
    padding: 0.25em 0.5em !important;
    margin-bottom: 1rem;
    font-weight: 600 !important;

    &-cyan {
      background-color: $th-cyan;
    }
    &-purple {
      background-color: $th-purple;
    }
  }
  .th-footer-items {
    display: flex;
    flex-direction: column;

    &.th-phone-wrapper {
      align-items: center;
      flex-direction: row;

      a,
      a:hover {
        color: $th-purple;
        cursor: pointer;
      }

      img {
        padding-right: 4px;
      }
    }
  }
  .privacy-items {
    margin-top: 4rem;

    a {
      padding-right: 2rem;
    }
  }

  .th-footer-item {
    cursor: pointer;
    color: #130217;
    font-weight: 400;

    &:hover {
      color: #130217b2;
    }
  }

  .th-footer-legal {
    span {
      padding-right: 1em;
    }
  }

  .th-legal-social {
    font-weight: 400;
    margin: 1em 0 0 0;
  }

  .th-footer-social {
    display: flex;
    justify-content: flex-end;
    i {
      padding-left: 1em;
    }
  }

  @media (min-width: 787px) {
    .th-legal-social {
      border-top: none;
      padding-top: 1em;
    }
  }

  @media (min-width: 992px) {
    .th-footer-group-mobile {
      display: none !important;
    }
  }
  @media (max-width: 991px) {
    .th-footer-group-desktop {
      display: none !important;
    }
  }
  @media (max-width: 768px) {
    #th-footer-info {
      padding: 1em;
    }
    .th-footer-group {
      min-width: 100%;
      padding-bottom: 1em;
      border-bottom: none;
    }

    .th-footer-items {
      flex-wrap: wrap;
      justify-content: space-between;
      // max-height: 5em;

      .th-footer-item {
        padding: 0 0.1em;
      }
    }

    .th-footer-legal {
      margin-bottom: 1em;
    }

    .th-footer-social {
      justify-content: flex-start;
      i {
        padding-left: 0;
        padding-right: 1em;
      }
    }
  }
  .social-links {
    display: flex;
    justify-content: space-between;
  }
}
.btns {
  margin-top: 24px;

  .btn-primary,
  .btn-violet {
    @include border-style($radius: 50px);
    min-width: 170px;
    font-size: 15px;
  }

  button {
    a {
      color: white;
    }

    &.btn-violet {
      background: $th-purple;
      border: 1px solid $th-purple;
      margin-top: 16px;
      color: white;

      &:hover {
        background: $th-purple;
      }
    }
  }
}

