@import '~styles/variables';
@import '~styles/mixins';

.btnGradient {
  &:hover{
    border: transparent!important;
    background: linear-gradient(93.03deg, #00D4A6 3.09%, #0F91FF 39.56%, #CD73FF 79.05%, #F97691 98.43%)!important;
  }
}
.ukhomeWrapPadding{
    padding-left: 101px!important;
    padding-right: 96px!important;
}

// lg
@media screen and (min-width:992px) and (max-width:1200px) {
    .ukhomeWrapPadding{
      padding-left: 80px!important;
      padding-right: 80px!important;
    }
  }
  // md
  @media screen and (min-width:768px) and (max-width:992px) {
    .ukhomeWrapPadding{
      padding-left: 100px!important;
      padding-right: 100px!important;
    }
  }
  // sm
  @media screen and (max-width:768px) {
    .ukhomeWrapPadding{
      padding-left: 20px!important;
      padding-right: 20px!important;
    }
  }