@import '~styles/mixins';
@import '~styles/variables';

.mobile {
  display: none;
}

.desktop {
  display: inline-block;
}

.termsLink {
  color: $th-purple;
  &:hover {
    text-decoration: underline;
    color: $th-purple;
  }
}

.submitFormBtn {
  @include border-style($radius: 10px);
  width: 100%;
  box-shadow: 0px 2px 4px rgba(23, 28, 52, 0.16);
}

.goLoginBtn {
  @include font-style(16px, $color: $th-purple);
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}

.goLoginText {
  @include font-style(16px);
}

.option {
  min-width: 100px;
}

.phoneNumberSelector {
  min-width: 120px;
}

@media screen and (max-width: 768px) {
  .mobile {
    display: inline-block;
  }

  .desktop {
    display: none;
  }
}
